@import url("https://use.fontawesome.com/releases/v5.5.0/css/all.css");

@media (min-width: 1200px) {
    .container {
        padding: 0 7%;
        max-width: none !important;
    }
}

@media (max-width: 468px) {
    .mobile_width {
        width: 100% !important;
    }

    .mobile_date {
        display: block !important;
    }

    .browser_date {
        display: none !important;
    }

    .table_overflow_x {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        border: none;
    }

    .form_group_wrap {
        margin: 0 !important;
    }

    .show_form {
        flex-flow: row wrap;
    }

    .resposive_width {
        width: 100% !important;
    }

    .view_claim_modal {
        max-width: 95% !important;
    }

    .view_claim_journey_email_modal {
        max-width: 95% !important;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 580px;
        margin: 1.75rem auto;
    }
}

.btn-outline-secondary {
    color: #23282c;
    border-color: #23282c;
}

body {
    padding-right: 0px;
}

// a{
//   color: #e87e04 !important;
// }

h3 {
    font-size: 24px;
}

label {
    margin-bottom: 1px;
}

.color_red {
    color: red;
}

.font-normal {
    font-weight: normal;
}

.font_color_grey {
    color: #92a0b9 !important;
}

.font_yellow_gold {
    color: #e87e04 !important;
}

.bold {
    font-weight: 700 !important;
}

.bordered {
    border: 1px solid #e7ecf1 !important;
}

.light {
    padding: 12px 20px 15px;
    background-color: #fff;
}

div[class*="icheckbox_"],
div[class*="iradio_"] {
    margin-right: 5px;
    top: -1px !important;
}

.invalid-text {
    color: #f86c6b !important;
}

.invalid {
    .form-control {
        border: 1px solid #f86c6b !important;
    }
}

.invalid-file {
    border: 1px solid #f86c6b !important;
}

.invalid-select {
    & > div {
        border: 1px solid #f86c6b !important;
    }
}

.form-control {
    border-radius: 0;
    border: 1px solid #c2cad8;
}

.dotted-top {
    border-top: 1px dotted;
    padding-top: 15px;
    color: #fff;
    margin-top: -5px;
}

.dotted-bottom {
    border-bottom: 1px dotted;
    padding-top: 15px;
    color: #fff;
    margin-top: -5px;
}

.wordWrap {
    word-wrap: break-word;
}

.w_5 {
    width: 5%;
}

.w_10 {
    width: 10%;
}

.w_20 {
    width: 20%;
}

.w_30 {
    width: 30%;
}

.w_40 {
    width: 40%;
}

.w_50 {
    width: 50%;
}

.w_60 {
    width: 60%;
}

.w_70 {
    width: 70%;
}

.w_90 {
    width: 90%;
}

.btn-primary {
    color: #fff;
    background-color: #e87e04;
    border-color: #e87e04;

    &:active {
        color: #fff !important;
        background-color: #e87e04 !important;
        border-color: #e87e04 !important;
    }

    &:focus-visible {
        box-shadow: 0 0 0 0.2rem rgba(233, 174, 107, 0.5);
    }

    &:focus-within {
        box-shadow: 0 0 0 0.2rem rgba(233, 174, 107, 0.5);
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(233, 174, 107, 0.5);
    }

    &:hover {
        color: #fff;
        background-color: #ff8800;
        border-color: #ff8800;

        a {
            color: #fff;
        }
    }
}

#login_container {
    .container {
        #container_row {
            height: 100%;
            justify-content: flex-end !important;

            #container_row_col {
                margin-bottom: 30px;
                align-self: flex-end;

                #login_card {
                    background: rgba(0, 0, 0, 0.5);
                    border: none;
                    border-radius: 25px;
                    padding: 20px 30px 15px 30px !important;
                    text-align: center;
                    align-items: flex-end;

                    .card-body {
                        width: 100%;
                        padding: 0px;

                        h1 {
                            width: 90%;
                            color: #fff;
                        }

                        .user_option {
                            text-align: left;
                            min-height: 38px;
                            color: #fff;
                            padding: 7px;
                            transition-duration: 0.2s;

                            span {
                                margin-left: 7px;
                                font-size: 14px;
                                display: inline-block;
                                vertical-align: middle;
                            }

                            .user_option_icon {
                                height: 38px;
                                width: 38px;
                            }

                            &:hover {
                                background: rgba(255, 255, 255, 0.15);
                                cursor: pointer;
                                box-shadow: 2px 2px 10px #000;
                                transition-duration: 0.2s;
                            }
                        }
                        #validation {
                            display: none;
                            text-align: left;
                        }

                        #incorrect {
                            display: none;
                            text-align: left;
                        }

                        .pull-right {
                            margin-top: 10px;
                        }
                    }

                    h1 {
                        font-size: 18px;
                        font-weight: 700;
                        color: #fff;
                        margin-bottom: 25px;
                    }

                    .text_muted {
                        color: #fff;
                        padding: 20px 0px 10px 0px;
                        text-align: left;
                        margin-top: 15px;
                    }

                    #login_btn_row {
                        text-align: right;
                    }
                }
            }
        }
    }
}

.btn-danger {
    // background-color: transparent;
}

.icon-button-down {
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.copy_from_payee_icon {
    span {
        color: #e87e04;
    }
    &:hover {
        cursor: pointer;
    }
}

.open_tab {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

#disclaimer_container {
    .container {
        .caption {
            color: #666;

            h3 {
                margin-top: 0;
            }
        }

        .form_group_wrap {
            width: 100%;
            margin-left: 8.5%;

            .form_group {
                margin-bottom: 15px;

                .form-check-label {
                    margin-left: 0px !important;
                    margin-right: 17px;
                }
            }
        }

        .show_form {
            display: flex !important;
        }

        .inner_form {
            display: none;
        }
    }
}

.toggler_header_wrap {
    display: flex;
    background-color: #e87e04;
    color: #fff;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    justify-content: space-between;

    .toggler_header {
        width: 100%;
        display: block;
        padding: 10px;
        &:hover {
            cursor: pointer;
        }
    }

    i {
        padding: 10px;
        &:hover {
            cursor: pointer;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

.radioBtn {
    margin: 0 10px;
}

.d_flex_column_reverse {
    display: flex;
    flex-direction: column-reverse;
}

.dashboard_filter_row {
    margin-top: 15px;
    margin-bottom: 15px;

    div {
        div {
            padding: 0px;
        }
    }
}

.toggler_inner {
    padding: 5px 15px;

    .form-body {
        padding: 20px 0px;
    }
}

.modal-header {
    .modal-title {
        color: #e87e04;
    }
}

.feedback-modal-footer {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.feedback-modal-body {
    display: flex;
    justify-content: space-between;
    margin: 30px;

    .feedback-modal-body-icon {
        .cui-ban {
            background-color: #f64846;
            border: 3px solid #f64846;
            padding: 10px;
            color: #fff;
            border-radius: 50%;
        }

        .cui-check {
            background-color: #4dbd74;
            border: 3px solid #4dbd74;
            padding: 10px;
            color: #fff;
            border-radius: 50%;
        }
    }
}

.ol_padding {
    padding-left: 16px;
}

.border_top {
    padding: 8px;
    width: 100%;
    border-top: 1px solid #e7ecf1;
}

.w-40 {
    width: 40% !important;
}

.w-12 {
    width: 12%;
}

.feedback-modal-body-text {
    text-align: center !important;
}

.progress-step-wrap {
    display: flex;
    justify-content: center;
    background-color: #fff;
    margin: 10px;
    padding: 15px;

    .progress-step {
        margin: 0;
        padding: 0;
        width: 100%;
        z-index: 999;

        .active {
            &::before {
                color: #fff;
                border: 2px solid #4dbd74;
                background-color: #4dbd74;
            }

            &::after {
                background-color: #4dbd74;
            }
        }

        li {
            font-size: 18px;
            list-style-type: none;
            width: 33%;
            float: left;
            position: relative;
            text-align: center;
            color: #7d7d7d;

            &:first-child:after {
                content: none;
            }

            &:first-child::before {
                content: "\f007";
                font-family: "Font Awesome 5 Free";
            }

            &:nth-child(2)::before {
                content: "\f15c";
                font-family: "Font Awesome 5 Free";
            }

            &:nth-child(3)::before {
                content: "\f14a";
                font-family: "Font Awesome 5 Free";
            }

            &::before {
                width: 50px;
                height: 50px;
                counter-increment: step;
                line-height: 50px;
                border: 2px solid #7d7d7d;
                display: block;
                text-align: center;
                margin: 0 auto 10px auto;
                border-radius: 50%;
                background-color: white;
            }

            &::after {
                width: 100%;
                height: 3px;
                content: "";
                position: absolute;
                background-color: #7d7d7d;
                top: 25px;
                left: -50%;
                z-index: -1;
            }
        }
    }
}
.modal-loading-body {
    text-align: center;

    .modal-loading-bar {
        margin: auto;
    }
}

#endorsement_container,
#new_claim_container,
#claim_container,
#follow_up_container {
    .caption {
        color: #ffffff;
        padding: 11px 0 9px;
        font-size: 16px;
        font-weight: 600;

        i {
            margin-top: 4px;
            display: inline-block;
            font-size: 13px;
            margin-right: 5px;
        }
    }
}

#personalData {
    .statement_label {
        width: 100%;
        padding: 8px;
        border-top: 1px solid #e7ecf1;
    }
}

.portlet {
    margin-top: 0;
    margin-bottom: 25px;

    .portlet_title {
        border-bottom: 1px solid #eef1f5;
        margin-bottom: 10px;
        padding: 0 10px;
    }
}

.portlet.box > .portlet_body {
    background-color: #fff;
    padding: 15px;
}

.no_border {
    border: 0px !important;
}

.yellow_gold {
    background-color: #e87e04;
}

.rdt {
    position: relative;
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 319px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #f9f9f9;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
    display: block;
}
.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}

.rdtPicker .rdtTimeToggle {
    text-align: center;
}

.rdtPicker table {
    width: 100%;
    margin: 0;
}
.rdtPicker td,
.rdtPicker th {
    text-align: center;
    height: 28px;
}
.rdtPicker td {
    cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
    color: #999999;
}
.rdtPicker td.rdtToday {
    position: relative;
}
.rdtPicker td.rdtToday:before {
    content: "";
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #e87e04;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: #e87e04 !important;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
    color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
}
.rdtPicker th.rdtSwitch {
    width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
    cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
}

.rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}
.rdtPicker button:hover {
    background-color: #eee;
}

.rdtPicker thead button {
    width: 100%;
    height: 100%;
}

td.rdtMonth,
td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
    background: #eee;
}

.rdtCounters {
    display: inline-block;
}

.rdtCounters > div {
    float: left;
}

.rdtCounter {
    height: 100px;
}

.rdtCounter {
    width: 40px;
}

.rdtCounterSeparator {
    line-height: 100px;
}

.rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}
.rdtCounter .rdtBtn:hover {
    background: #eee;
}
.rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
}

.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}

.rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
}

.rdtTime td {
    cursor: default;
}

.btn {
    a {
        color: #e87e04;

        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }
}

.link_underline_wrap {
    text-align: left;
    font-weight: 100;
}

.link_underline {
    padding-right: 15px;
    display: inline;
    color: #fff;

    &:hover {
        cursor: pointer;
    }
}

.admin_claim_list {
    background-color: #fff;
    margin-top: 20px;

    thead {
        font-weight: 600;
    }

    tbody {
        .modal-backdrop.show {
            opacity: 0.1;
        }
    }
}

.claim_summary_table {
    tr {
        td {
            width: 50%;
        }
    }
}

.btn {
    margin-top: 1em;
}

button {
    margin-left: 5px;
    margin-right: 5px;
}

#overview_wrap {
    .card {
        margin-top: 1em;
    }
}
table {
    .btn {
        margin-top: 0 !important;
    }
}

.dashboard_button_across {
    .col {
        text-align: center;
    }
    button {
        width: 90%;
        margin-top: 0 !important;
        white-space: initial;
    }
}

#dashboard_nav_tab {
    a {
        &:hover {
            cursor: pointer;
        }
    }

    .active {
        font-weight: 700 !important;
    }
}

.row_chart {
    margin-top: 20px;
}

.paginate_active {
    a {
        background-color: #e87e04 !important;
        color: #fff !important;
    }
}

.paginate_page > a {
    transition: all 0.2s ease-in;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #c8ced3;
    padding: 5px 10px;
    margin: 3px;

    &:hover {
        cursor: pointer;
        background-color: #e4e7ea;
        text-decoration: none;
    }
}

.row_datetime_picker {
    margin-bottom: 30px;
}

.sidebar_welcome_user {
    display: block;
    padding: 0.75rem 1rem;
    flex: none;
    -ms-flex: none;

    .welcom_username {
        text-align: center;
    }
}

#landing_page_popup {
    .modal-content {
        border: none;
        background: transparent;

        .modal-body {
            height: 100%;
            padding: 0;
        }
    }
}

.popup_body {
    position: relative;

    #popup_background {
        width: 100%;
        height: auto;
    }

    #popup_btn_wrap {
        position: absolute;
        top: 62%;
        text-align: center;

        img {
            &:hover {
                cursor: pointer;
            }
        }
    }

    img {
        width: 65%;
        height: auto;
    }
}

.mobile_date {
    display: none;
}

.div_mobile_date {
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    color: #5c6873;
    background-color: #ffffff;
    border: 1px solid #c2cad8;
}

.datepicker-header,
.datepicker-navbar-btn {
    color: #e87e04 !important;
}

.datepicker-wheel {
    border-top: 1px solid #e87e04 !important;
    border-bottom: 1px solid #e87e04 !important;
}

.chart-wrapper {
    height: 50vh;
}

.text_center {
    text-align: center;
    display: block;
}

.hide_element {
    display: none;
}

.resposive_width {
    width: 50%;
    margin: auto;
}

.pagination {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 5px;
    white-space: nowrap;
}

.disabled_component {
    background-color: #e4e7ea !important;
}

.login-page-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 1px;
    color: white !important;

    a {
        padding: 0px 5px;
        color: white !important;
    }
}

.custom_w_20 {
    width: 20% !important;
}

.custom_w_5 {
    width: 5% !important;
}

.breadcrumb-item,
.paginate_page {
    a {
        color: #e87e04 !important;
    }
}

.paginate_active {
    a {
        color: #fff !important;
    }
}

.sidebar .nav-link {
    &:hover {
        background: #e87e04 !important;
    }
}

.sidebar .nav-link.active .nav-icon {
    color: #e87e04 !important;
}

.table_overflow_x_desktop {
    display: block !important;
    overflow-x: auto !important;
    border: none !important;
}

.emailReminderContent {
    a {
        color: #e87e04 !important;
    }
}

.view_claim_modal {
    max-width: 80%;
    margin: auto;
}

.view_claim_journey_email_modal {
    max-width: 70%;
    margin: auto;
}

.claim_journey_wrap {
    border: 1px solid #c8ced3;
    padding: 7px;
    margin: 10px 0px;
    border-radius: 3px;
    word-wrap: break-word;
}

.school_management_modal {
    max-width: 70%;
    margin: auto;
}

.internal_remarks_col {
    div {
        width: 100%;
        max-width: none;
        padding: 0px;

        .toggler_inner {
            padding: 0px;
            margin: 0px;
        }
    }
}

.view_claim_journey_table {
    table-layout: fixed;
    width: 100%;

    td {
        div {
            word-wrap: break-word;
        }
    }
}

.Modal-Portal {
    position: relative;
    z-index: 1051;
}

.nested-table-bg {
    background-color: #e4e5e6;
}

.school_audit_log_modal {
    max-width: 80%;

    .modal-body {
        overflow-x: scroll;
    }
}

.text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    display: block;
    overflow: hidden;
}

.admin_add_claim_wrapper {
    .container {
        padding: 0 !important;
    }
}

.position-absolute {
    position: absolute !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.datepicker-modal {
    position: fixed !important;
}

input:read-only {
    background-color: #fff !important;
}

.justify-center {
    justify-content: center !important;
}

input:readonly {
    background-color: #fff !important;
}

[readonly="readonly"].form-control {
    background-color: #fff !important;
}

[readonly="readonly"] {
    background-color: #fff !important;
}

.form-control[readonly=""] {
    background-color: #fff !important;
}

input[readonly] {
    background-color: #fff !important;
}

input[readonly="readonly"] {
    background-color: #fff !important;
}

[readonly].form-control {
    background-color: #fff !important;
    opacity: 1 !important;
}

input[readonly].form-control {
    background-color: #fff !important;
    opacity: 1 !important;
}

.maintenance-pop-up {
    min-width: 600px !important;
}

.account-manager-table {
    td {
        white-space: nowrap;
    }
}

.toast-hbl-activity {
  background-color: #fff3cd;
  color: red;
}

input {
  &:read-only.form-control.bg-grey {
    background-color: #e4e7ea !important;
  }

  &:disabled.form-control.bg-grey {
    background-color: #e4e7ea !important;
  }
}
